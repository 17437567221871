import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { BASE_URL } from '../../../config';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    userRole$ = this.store.state$.pipe(map(state => state.user?.role));
    username$ = this.store.state$.pipe(map(state => state.user?.name));
    avatar$ = this.store.state$.pipe(map(state => (state.user && state.user.image
        ? state.user.image : 'assets/images/user-placeholder.png')));

    constructor(
        public store: StoreService,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
    }

    onSignOutClick(): void {
        this.userService.signOut();
    }
}
