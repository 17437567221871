<div class='login-wrap'>
    <div class='login-wrap__inner'>
        <img routerLink='/' src='assets/images/logo.png' />
        <h4>Zneplatnění tokenu</h4>
        <div class="section--content">
            <p *ngIf='success' class='verify-email-success'>
                Token byl zneplatněn.
            </p>
            <p *ngIf='error' class='verify-email-error'>
                {{ error }}
            </p>
        </div>
        <div *ngIf="app === 'ADMIN'">
            <button routerLink='/' type="button" class="btn btn-block btn-secondary">Pokračovat na web</button>
        </div>
    </div>
</div>
