<div class='login-wrap'>
    <div class='login-wrap__inner'>
        <img routerLink='/' src='assets/images/logo.png' />
        <h4>Nastavení hesla</h4>
        <div class="section--content">

            <ng-container *ngIf='!success'>
                <p>
                    Nové heslo nastavíte zadáním do formuláře.
                </p>
                <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
                    <div class="form-group grey-color">
                        <label>Nové heslo</label>
                        <input formControlName='password' type="password" class="form-control" autocomplete='new-password' required>
                        <div *ngIf='submitted && password.invalid' class="invalid-feedback">
                            Zadejte prosím nové heslo o alespoň 6 znacích.
                        </div>
                    </div>
                    <div class="form-group grey-color">
                        <label>Nové heslo pro kontrolu</label>
                        <input formControlName='passwordCheck' type="password" class="form-control" autocomplete='new-password' required>
                        <div *ngIf='submitted && passwordCheck.invalid' class="invalid-feedback">
                            Zadejte prosím nové heslo pro kontrolu.
                        </div>
                        <div *ngIf='submitted && password.valid && passwordCheck.valid && passwordCheck.value !== password.value' class="invalid-feedback">
                            Hesla se neshodují.
                        </div>
                    </div>
                    <div class="login-wrap__buttons">
                        <button type="submit" class="btn btn-block btn-primary">Uložit</button>
                    </div>
                </form>
            </ng-container>



            <p *ngIf='success' class='verify-email-success'>
                Nastavení hesla proběhlo úspěšně. Nyní se můžete přihlásit.
            </p>
            <p *ngIf='error' class='verify-email-error'>
                {{ error }}
            </p>
        </div>
        <div *ngIf="app === 'ADMIN'">
            <button routerLink='/' type="button" class="btn btn-block btn-secondary">Pokračovat na web</button>
        </div>
    </div>
</div>

