import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { STRINGS } from 'src/assets/strings';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
}) export class ErrorHandlerService {

    unauthorizedError$: Subject<void> = new Subject();

    constructor(
        private alertService: AlertService,
    ) {

    }

    handleApiError(err: any, disableAuthErrorDispatch: boolean = false): void {
        console.log('error-handler handleApiError', err);
        if (!disableAuthErrorDispatch && err?.status === 401) {
            this.alertService.presentToast(STRINGS.error.unauthorized, 'danger');
            this.unauthorizedError$.next();
            return;
        }
        throw new Error(this.getErrorString(err));
    }

    private getErrorString(err: any): string {
        if (typeof err === 'string') {
            return err;
        }
        if (err?.status === 0) {
            return STRINGS.error.connection;
        }
        if (err?.error?.message) {
            return err.error.message;
        }
        if (err?.message) {
            return err.message;
        }
        return STRINGS.error.default;
    }
}
