<div class='alerts-wrapper'>
    <ng-container *ngFor="let alert of alertService.alerts">
        <app-alert-selfclosing [type]="alert.type" [timeout]='alert.timeout' [onClose]='alert.onClose'> {{ alert.msg }}</app-alert-selfclosing>
    </ng-container>
</div>
<div class="root-container" [class.root-container--login]='authSectionActive$ | async' [class.root-container--app]='(authSectionActive$ | async) === false'>
    <div class='container-fluid'>
        <div class="row">
            <div *ngIf='(user$ | async) && (user$ | async)?.isEmailVerified && (authSectionActive$ | async) === false' class="col p-0 sidebar-container">
                <app-sidebar></app-sidebar>
            </div>
            <div class="col router-container">
                <router-outlet>
                </router-outlet>
            </div>
        </div>
    </div>
</div>
<div *ngIf='loading$ | async' class='loading-top'>
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
