import { BASE_URL } from 'src/config';
import { UserRole } from './user-role';

export interface User {
    id: string;
    name: string;
    role: UserRole;
    image: string;
    address: string;
    description: string;
    phone: string;
    ic?: string;
    email: string;
    web: string;
    isBlocked: boolean;
    isEmailVerified: boolean;
}

export const dtoToUser = (dto: any): User => {
    if (dto.image && dto.image.indexOf('http') < 0) {
        dto.image = BASE_URL + dto.image;
    }
    return dto as User;
};
