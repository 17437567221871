<div class="py-4 h-100 d-flex flex-column"><img routerLink='/' src="assets/images/logo.png" class="logo mx-auto">
    <ng-container *ngIf='(userRole$ | async) === "ORGANIZER"'>
        <div class="user-info mt-5 d-flex flex-column align-items-center">
            <img [src]="avatar$ | async" class="avatar" />
            <div class="username mt-3 text-white text-center">{{username$ | async}}</div>
            <button routerLink="/organizer/profile" type="button" class="btn btn-info btn-sm mt-4">Editovat účet</button>
            <button (click)='onSignOutClick()' type="button" class="btn btn-outline-light btn-sm border-0 text-underline mt-2">Odhlásit se</button></div>
            <ul class="nav flex-column mt-5">
                <li class="nav-item"><a routerLink="/organizer/offers" routerLinkActive="active" class="nav-link text-white">Přehled mých nabídek</a></li>
                <li class="nav-item"><a routerLink="/organizer/offer/create" routerLinkActive="active" class="nav-link text-white">Vytvořit pracovní nabídku</a></li>
            </ul>
        </ng-container>
        <ng-container *ngIf='(userRole$ | async) === "ADMIN"'>
            <div class="user-info mt-5 d-flex flex-column align-items-center">
                <img [src]="avatar$ | async" class="avatar" />
                <div class="username mt-3 text-white text-center">Administrator</div>
                <button routerLink="/admin/profile" type="button" class="btn btn-info btn-sm mt-4">Editovat účet</button>
            <button  (click)='onSignOutClick()' type="button" class="btn btn-outline-light btn-sm border-0 text-underline mt-4">Odhlásit se</button></div>
        <ul class="nav flex-column mt-5">
            <li class="nav-item"><a routerLink="/admin/offers" routerLinkActive="active" class="nav-link text-white">Katalog nabídek</a></li>
            <li class="nav-item"><a routerLink="/admin/workers" routerLinkActive="active" class="nav-link text-white">Databáze providerů</a></li>
            <li class="nav-item"><a routerLink="/admin/organizers" routerLinkActive="active" class="nav-link text-white">Databáze organiserů</a></li>
            <li class="nav-item"><a routerLink="/admin/tags" routerLinkActive="active" class="nav-link text-white">Správa tagů</a></li>
        </ul>
    </ng-container>
</div>
