import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../components/alert/alert.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    alerts: any[] = [];

    constructor(
        private ngbModalService: NgbModal,
    ) {
    }

    presentAlert(title: string, message: string, confirmText?: string, onConfirm?: () => void): void {
        const modal = this.ngbModalService.open(AlertComponent);
        modal.componentInstance.header = title;
        modal.componentInstance.message = message;
        modal.componentInstance.buttonConfirm = confirmText || 'OK';
        modal.result
            .then(() => {
                if (onConfirm) {
                    onConfirm();
                }
            })
            .catch(() => {
            });
    }

    presentConfirm(options: {
        title: string,
        message: string,
        cancelText?: string,
        confirmText?: string,
        onConfirm?: () => void,
        onCancel?: () => void
    }): void {
        const modal = this.ngbModalService.open(ConfirmComponent);
        modal.componentInstance.header = options.title;
        modal.componentInstance.message = options.message;
        modal.componentInstance.buttonCancel = options.cancelText || 'Zpět';
        modal.componentInstance.buttonConfirm = options.confirmText || 'Potvrdit';
        modal.result
            .then(() => {
                if (options.onConfirm) {
                    options.onConfirm();
                }
            })
            .catch(() => {
                if (options.onCancel) {
                    options.onCancel();
                }
            });
    }

    presentToast(text: string, type: string = 'success', timeout: number = 2500): void {
        const alert = {
            type,
            msg: text,
            timeout,
            onClose: () => {
                this.alerts.splice(this.alerts.indexOf(alert), 1);
            }
        };
        this.alerts.push(alert);
    }
}
