/* tslint:disable */

import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { BASE_URL_API } from '../../config';
import { dtoToEvent } from '../models/event';
import { EventApplicantStatus } from '../models/event-applicant-status';
import { dtoToEventDetail, EventDetail } from '../models/event-detail';
import { EventForm } from '../models/event-form';
import { EventStatus } from '../models/event-status';
import { dtoToOrganizer, Organizer } from '../models/organizer';
import { OrganizerForm } from '../models/organizer-form';
import { StorageKeys } from '../models/storage-keys';
import { dtoToTagGroups, TagGroup } from '../models/tag-group';
import { dtoToUser, User } from '../models/user';
import { UserProfileForm } from '../models/user-profile-form';
import { dtoToProvider, Provider } from '../models/provider';
import { ProviderForm } from '../models/provider-form';
import { StorageService } from './storage.service';
import { StoreService } from './store.service';
@Injectable({
    providedIn: 'root',
})
export class ApiService {

    private token: string | undefined = undefined;

    constructor(
        private storage: StorageService,
        private store: StoreService,
        private http: HttpClient,
    ) {
    }

    setToken(token: string): void {
        this.token = token;
    }

    reloadUser(): Observable<User> {
        return this.getUser();
    }

    signIn(email: string, password: string): Observable<User> {
        const endpoint = `${BASE_URL_API}/auth/admin/sign-in`;
        return this.http
            .post<any>(
                endpoint,
                {
                    username: email,
                    password: password
                }
            ).pipe(
                map(resp => resp.access_token),
                concatMap(token => {
                    this.token = token;
                    this.storage.set(StorageKeys.API_TOKEN, token);
                    return this.getUser();
                })
            );
    }

    signUp(email: string, password: string, ic: string, username: string): Observable<User> {
        const endpoint = `${BASE_URL_API}/auth/admin/sign-up`;
        return this.http
            .post<any>(
                endpoint,
                {
                    email: email,
                    password: password,
                    ic: ic,
                    name: username,
                }
            ).pipe(
                map(resp => resp.access_token),
                concatMap(token => {
                    this.token = token;
                    this.storage.set(StorageKeys.API_TOKEN, token);
                    return this.getUser();
                })
            );
    }

    signOut(): Observable<void> {
        this.token = undefined;
        this.storage.set(StorageKeys.API_TOKEN, undefined);
        return of();
    }

    resetPassword(email: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/auth/lost-password`;
        return this.http.post<void>(endpoint, { email, appType: 'ADMIN' });
    }

    setPassword(resetToken: string, newPassword: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/auth/new-password/${resetToken}`;
        return this.http.post<void>(endpoint, { password: newPassword });
    }

    resendVerificationEmail(email: string, app: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/auth/resend-verification-email`;
        return this.http.post<void>(endpoint, {
            email,
            appType: app
        });
    }

    verifyEmail(verificationToken: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/auth/validate-email-token/${verificationToken}`;
        return this.http.get<void>(endpoint);
    }

    invalidateToken(verificationToken: string, tokenType: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/auth/invalidate-token/${verificationToken}`;
        return this.http.post<void>(endpoint, {
            type: tokenType
        });
    }

    editProfile(userId: string, form: Partial<UserProfileForm>): Observable<User> {

        const endpoint = `${BASE_URL_API}/admin/organizers/${userId}`;
        const formData = new FormData();
        const jsonBody = {
            id: userId,
            name: form.username,
            phone: form.phone,
            description: form.description,
            address: form.address,
            webUrl: form.webUrl,
            ic: form.ic,
        };
        formData.append('data', JSON.stringify(jsonBody));
        formData.append('files', form.avatar || '');
        if (form.avatar === null) {
            formData.append('deletePhoto', 'true');
        }
        return this.http
            .put<any>(
                endpoint,
                formData
                , { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => {
                    return dtoToUser(resp)
                })
            );
    }

    changePassword(oldPassword: string, newPassword: string): Observable<void> {
        let state = this.store.getState();

        const endpoint = `${BASE_URL_API}/admin/organizers/${state.user?.id}/change-password`;
        return this.http
            .put<any>(
                endpoint, {
                oldPassword: oldPassword,
                password: newPassword
            }, { headers: this.getAuthHeaders() }
            )
    }

    changePasswordAsSuperadmin(id: string, password: string) {
        const endpoint = `${BASE_URL_API}/admin/organizers/${id}/change-password`;
        return this.http
            .put<any>(
                endpoint, {
                password: password
            }, { headers: this.getAuthHeaders() }
            )
    }

    getEventDetail(id: string): Observable<EventDetail> {
        const endpoint = `${BASE_URL_API}/admin/events/${id}`;
        return this.http
            .get<any>(
                endpoint, { headers: this.getAuthHeaders() }
            ).pipe(
                map((resp: any) => {
                    return dtoToEventDetail(resp);
                })
            );
    }

    createEvent(form: EventForm): Observable<string> {
        const endpoint = `${BASE_URL_API}/admin/organizers/events`;
        const formData = new FormData();
        let state = this.store.getState();

        let tags = form.tags.map(s => ({ id: +s }));

        const jsonBody = {
            organizerId: state?.user?.id,
            title: form.title,
            date: form.date,
            description: form.description,
            photo: form.photo,
            tags: tags
        };
        formData.append('data', JSON.stringify(jsonBody));
        formData.append('files', form.photo || '');
        if (form.photo === null) {
            formData.append('deletePhoto', 'true');
        }

        return this.http
            .post<any>(
                endpoint,
                formData
                , { headers: this.getAuthHeaders() }
            ).pipe(map(resp => resp.id));
    }

    updateEvent(id: string, form: EventForm): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/events/${id}`;
        const formData = new FormData();
        let tags = form.tags.map(s => ({ id: +s }));
        const jsonBody = {
            title: form.title,
            date: form.date,
            description: form.description,
            photo: form.photo,
            tags: tags
        };
        formData.append('data', JSON.stringify(jsonBody));
        formData.append('files', form.photo || '');
        if (form.photo === null) {
            formData.append('deletePhoto', 'true');
        }

        return this.http
            .put<any>(
                endpoint,
                formData
                , { headers: this.getAuthHeaders() }
            );
    }

    deleteEvent(id: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/events/${id}`;
        return this.http
            .delete<any>(
                endpoint, { headers: this.getAuthHeaders() }
            );
    }

    connectWorker(workerId: string, eventId: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/events/${eventId}/connect/${workerId}`;
        return this.http
            .put<any>(
                endpoint, {}, { headers: this.getAuthHeaders() }
            )
    }

    disconnectWorker(workerId: string, eventId: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/events/${eventId}/disconnect/${workerId}`;
        return this.http
            .put<any>(
                endpoint, {},
                { headers: this.getAuthHeaders() }
            )
    }

    ignoreWorker(workerId: string, eventId: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/events/${eventId}/ignore/${workerId}`;
        return this.http
            .put<any>(
                endpoint, {},
                { headers: this.getAuthHeaders() }
            )
    }

    rateWorker(workerId: string, eventId: string, rating: number): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/events/${eventId}/rate/${workerId}`;
        return this.http
            .put<any>(
                endpoint,
                {
                    points: rating
                },
                { headers: this.getAuthHeaders() }
            )
    }

    getWorkerDetail(id: string): Observable<Provider> {
        const endpoint = `${BASE_URL_API}/admin/users/${id}`;
        return this.http
            .get<any>(
                endpoint,
                { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => dtoToProvider(resp))
            );
    }

    updateWorker(id: string, form: ProviderForm): Observable<Provider> {

        const endpoint = `${BASE_URL_API}/admin/users/${id}`;
        const formData = new FormData();
        const jsonBody = {
            id: id,
            name: form.name,
            surname: form.surname,
            birthdate: form.birthdate,
            phone: form.phone,
            avatar: form.image?.name,
            description: form.description,
            tags: form.tags?.map(t => {
                return { id: t }
            }),
        };
        formData.append('data', JSON.stringify(jsonBody));
        formData.append('files', form.image || '');
        if (form.image === null) {
            formData.append('deletePhoto', 'true');
        }
        return this.http
            .put<any>(
                endpoint,
                formData
                , { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => {
                    return dtoToProvider(resp)
                })
            );
    }

    unblockWorker(id: string): Observable<void> {
        return this.http
            .put<any>(
                `${BASE_URL_API}/admin/users/${id}/block`,
                { block: false },
                { headers: this.getAuthHeaders() }
            );
    }

    blockWorker(id: string): Observable<void> {
        return this.http
            .put<any>(
                `${BASE_URL_API}/admin/users/${id}/block`,
                { block: true },
                { headers: this.getAuthHeaders() }
            );
    }

    getOrganizerDetail(id: string): Observable<Organizer> {
        const endpoint = `${BASE_URL_API}/admin/organizers/${id}/detail`;
        return this.http
            .get<any>(
                endpoint,
                { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => dtoToOrganizer(resp))
            );
    }

    createOrganizer(form: OrganizerForm): Observable<Organizer> {
        const endpoint = `${BASE_URL_API}/admin/organizers`;
        const formData = new FormData();
        const jsonBody = {
            password: form.password,
            name: form.username,
            email: form.email,
            phone: form.phone,
            description: form.description,
            address: form.address,
            webUrl: form.webUrl,
            ic: form.ic
        };
        formData.append('data', JSON.stringify(jsonBody));
        formData.append('files', form.image || '');

        return this.http
            .post<any>(
                endpoint,
                formData
                , { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => {
                    return dtoToOrganizer(resp)
                })
            );
    }

    updateOrganizer(id: string, form: Partial<OrganizerForm>): Observable<Organizer> {
        const endpoint = `${BASE_URL_API}/admin/organizers/${id}`;
        const formData = new FormData();
        console.log(form.password)
        const jsonBody = {
            name: form.username,
            email: form.email,
            phone: form.phone,
            description: form.description,
            address: form.address,
            webUrl: form.webUrl,
            ic: form.ic,
        };
        formData.append('data', JSON.stringify(jsonBody));
        formData.append('files', form.image || '');
        if (form.image === null) {
            formData.append('deletePhoto', 'true');
        }
        return this.http
            .put<any>(
                endpoint,
                formData
                , { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => {
                    return dtoToOrganizer(resp)
                })
            );
    }

    blockOrganizer(id: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/organizers/${id}/block`;
        return this.http
            .put<any>(
                endpoint,
                { block: true }
                , { headers: this.getAuthHeaders() }
            )
    }

    unblockOrganizer(id: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/organizers/${id}/block`;
        return this.http
            .put<any>(
                endpoint,
                { block: false }
                , { headers: this.getAuthHeaders() }
            )
    }

    getTagGroups(): Observable<TagGroup[]> {
        const endpoint = `${BASE_URL_API}/admin/tags`;
        return this.http
            .get<any>(
                endpoint
                , { headers: this.getAuthHeaders() }
            ).pipe(
                map(resp => {
                    return dtoToTagGroups(resp)
                })
            );
    }

    addTagGroup(name: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/tags/tag-group`;
        return this.http
            .post<any>(
                endpoint,
                {
                    title: name,
                }
                , { headers: this.getAuthHeaders() }
            )
    }

    updateTagGroupName(groupId: number, name: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/tags/tag-group/${groupId}`;
        return this.http
            .put<any>(
                endpoint,
                {
                    title: name,
                }
                , { headers: this.getAuthHeaders() }
            )
    }

    addTag(groupId: number, tagName: string): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/tags`;
        return this.http
            .post<any>(
                endpoint,
                {
                    title: tagName,
                    groupId: groupId,
                }
                , { headers: this.getAuthHeaders() }
            )
    }

    deleteTagGroup(groupId: number): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/tags/tag-group/${groupId}`;
        return this.http
            .delete<any>(
                endpoint
                , { headers: this.getAuthHeaders() }
            )
    }

    removeTag(tagId: number): Observable<void> {
        const endpoint = `${BASE_URL_API}/admin/tags/${tagId}`;
        return this.http
            .delete<any>(
                endpoint, { headers: this.getAuthHeaders() }
            )
    }

    getList(key: string, offset: number, limit: number, search?: string, sortColumn?: string, sortDirection?: string, detailId?: string): Observable<{ total: number, items: any[] }> {
        let state = this.store.getState();
        switch (key) {
            case 'organizerOffers': {
                const endpoint = `${BASE_URL_API}/admin/organizers/${state.user?.id}/events`;
                return this.http
                    .post<any>(
                        endpoint,
                        {
                            sort: sortColumn,
                            order: sortDirection,
                            pattern: search,
                            offset,
                            limit,
                            tags: []
                        }
                        , { headers: this.getAuthHeaders() }
                    ).pipe(
                        map((resp: any) => {
                            return ({
                                total: resp.total,
                                items: resp.data.map((item: any) => dtoToEvent(item)),
                            })
                        })
                    );
            }
            case 'adminOffers': {
                const endpoint = `${BASE_URL_API}/admin/events`;
                return this.http
                    .post<any>(
                        endpoint,
                        {
                            sort: sortColumn,
                            order: sortDirection,
                            pattern: search,
                            offset,
                            limit,
                            tags: []
                        }
                        , { headers: this.getAuthHeaders() }
                    ).pipe(
                        map((resp: any) => {
                            return ({

                                total: resp.total,
                                items: resp.data.map((item: any) => dtoToEvent(item)),
                            })
                        }
                        )
                    );
            }
            case 'adminWorkers': {
                const endpoint = `${BASE_URL_API}/admin/users/`;
                return this.http
                    .post<any>(
                        endpoint,
                        {
                            sort: sortColumn,
                            order: sortDirection,
                            pattern: search,
                            offset,
                            limit,
                            tags: []
                        }
                        , { headers: this.getAuthHeaders() }
                    ).pipe(
                        map((resp: any) => {
                            return ({
                                total: resp.total,
                                items: resp.data.map((item: any) => dtoToProvider(item)),
                            })
                        })
                    );
                break;
            }
            case 'adminOrganizers': {
                const endpoint = `${BASE_URL_API}/admin/organizers/search`;
                return this.http
                    .post<any>(
                        endpoint,
                        {
                            sort: sortColumn,
                            order: sortDirection,
                            pattern: search,
                            offset,
                            limit,
                            tags: []
                        }
                        , { headers: this.getAuthHeaders() }
                    ).pipe(
                        map((resp: any) => {
                            console.log(resp)
                            return ({
                                total: resp.total,
                                items: resp.data.map((item: any) => dtoToOrganizer(item)),
                            })
                        }
                        )
                    );

            }
            case 'adminOrganizerEvents': {
                const endpoint = `${BASE_URL_API}/admin/organizers/${detailId}/events`;
                return this.http
                    .post<any>(
                        endpoint,
                        {
                            sort: sortColumn,
                            order: sortDirection,
                            pattern: search,
                            offset,
                            limit,
                            tags: []
                        }
                        , { headers: this.getAuthHeaders() }
                    ).pipe(
                        map((resp: any) => {
                            return ({
                                total: resp.total,
                                items: resp.data.map((item: any) => dtoToEvent(item)),
                            })
                        }
                        )
                    );

            }
            default:
                return of();
        }
    }

    private getUser(): Observable<User> {
        const endpoint = `${BASE_URL_API}/admin/organizers/detail`;
        return this.http
            .get<any>(endpoint, { headers: this.getAuthHeaders() })
            .pipe(
                map(resp => dtoToUser(resp))
            );
    }

    private getAuthHeaders(): { [key: string]: string } {
        return {
            Authorization: `Bearer ${this.token}`,
        };
    }


}
