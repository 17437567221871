import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';
import { InitService } from './init.service';
import { StoreService } from './store.service';
import { UserRole } from '../models/user-role';
import { UserProfileForm } from '../models/user-profile-form';

@Injectable({
    providedIn: 'root'
}) export class UserService {

    constructor(
        private router: Router,
        private apiService: ApiService,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
        private alertService: AlertService,
        private initService: InitService,
        private store: StoreService,
    ) {
    }

    initialize(): void {
        this.errorHandlerService.unauthorizedError$.subscribe({
            next: this.signOut.bind(this)
        });
        if (this.initService.user) {
            this.store.dispatch({ user: this.initService.user });
        }
    }

    async signIn(email: string, password: string): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.signIn(email, password).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        // success
        this.onSignIn(user);
    }

    async signUp(email: string, password: string, ic: string, username: string): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.signUp(email, password, ic, username).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        // success
        this.onSignIn(user);
    }

    async resetPassword(email: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.resetPassword(email).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async setPassword(resetToken: string, newPassword: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.setPassword(resetToken, newPassword).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async editProfile(form: Partial<UserProfileForm>): Promise<void> {
        form.phone = form.phone?.replace(/ /g, '');
        let user = this.store.getState().user;
        if (!user) {
            return;
        }
        this.loadingService.loading$.next(true);
        try {
            user = await this.apiService.editProfile(user.id, form).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        // success
        this.store.dispatch({ user });
        this.alertService.presentToast('Nastavení profilu bylo uloženo.');
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.changePassword(oldPassword, newPassword).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        // success
        this.alertService.presentAlert('Změna hesla', 'Nové heslo bylo nastaveno.');
    }

    async resendVerificationEmail(email: string, app: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.resendVerificationEmail(email, app).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        // success
        this.alertService.presentToast(`Ověřovací e-mail byl odeslán na vaši adresu ${email}.`);
    }

    async checkEmailVerified(): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.reloadUser().toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        // success
        this.store.dispatch({ user });
    }

    async verifyEmail(verificationToken: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.verifyEmail(verificationToken).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async invalidateToken(verificationToken: string, tokenType: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.invalidateToken(verificationToken, tokenType).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async signOut(): Promise<void> {
        try {
            await this.apiService.signOut();
        } catch (err) {
        }
        this.store.clear();
        this.alertService.presentToast('Uživatel byl odhlášen.');
        this.router.navigateByUrl('/auth');
    }

    private onSignIn(user: User): void {
        this.store.dispatch({ user });
        if (user.isEmailVerified) {
            this.alertService.presentToast('Přihlášení proběhlo úspěšně.');
        }
        if (user.role === UserRole.ADMIN) {
            this.router.navigateByUrl('/admin');
        } else {
            this.router.navigateByUrl('/organizer');
        }
    }
}
