import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from '../../../../services/store.service';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

    public error = '';
    public success = false;
    public app = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private router: Router,
        private alertService: AlertService,
        private store: StoreService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const token = this.activatedRoute.snapshot.queryParams.token;
        this.app = this.activatedRoute.snapshot.queryParams.app;
        try {
            await this.userService.verifyEmail(token);
        } catch (err) {
            console.log('error', err);
            this.error = err.message;
            return;
        }
        this.alertService.presentToast('Děkujeme! Registrace proběhla úspěšně.');
        this.success = true;
    }

}
