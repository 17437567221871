<div class="modal-header">
    <h4 class="modal-title">{{header}}</h4>
</div>
<div class="modal-body">
    <p>{{message}}</p>
</div>
<div class="modal-footer">
    <button (click)='modal.dismiss()' type="button" class="btn btn-outline-dark border-0">{{buttonCancel}}</button>
    <button (click)='modal.close()' type="button" class="btn btn-danger">{{buttonConfirm}}</button>
</div>
