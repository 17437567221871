import { EventStatus } from 'src/app/models/event-status';

export const STRINGS = {
    error: {
        connection: 'Ověřte připojení k internetu.',
        unauthorized: 'Uživatel byl odhlášen. Přihlaste se prosím znovu.',
        default: 'Neznámá chyba',
    },
    eventStatus: {
        [EventStatus.ACTIVE]: 'Aktivní',
        [EventStatus.ENDED]: 'Ukončena',
        [EventStatus.DELETED]: 'Smazána',
    }
};
