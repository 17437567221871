import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserRole } from '../models/user-role';
import { StoreService } from '../services/store.service';

@Injectable({
    providedIn: 'root'
}) export class AuthGuard implements CanActivate {


    constructor(
        private store: StoreService,
        public router: Router,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const user = this.store.getState().user;
        const url = route.toString();
        if (!user && (url.includes('organizer') || url.includes('admin'))) {
            this.router.navigateByUrl('/auth');
            return false;
        }
        if (user?.role === UserRole.ADMIN && user?.isEmailVerified && (url.includes('organizer') || url.includes('auth'))) {
            this.router.navigateByUrl('/admin');
            return false;
        }
        if (user?.role === UserRole.ORGANIZER && user?.isEmailVerified && (url.includes('admin') || url.includes('auth'))) {
            this.router.navigateByUrl('/organizer');
            return false;
        }
        return true;
    }


}
