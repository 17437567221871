import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pairwise, tap } from 'rxjs/operators';
import { State } from '../models/state';

type Dispatch = (partial: Partial<State>) => void;

const getEmptyState = (): State => {
    return {
        user: undefined,
        apiToken: undefined,
    };
};

let state: State = getEmptyState();
const state$: BehaviorSubject<State> = new BehaviorSubject(state);

state$.pipe(
    pairwise(),
    tap(([prev, next]) => {
        // console.log('*** STATE UPDATE ***');
        if (prev && next) {
            const diffs = Object.keys(next).reduce((diff, key) => {
                if ((prev as any)[key] === (next as any)[key]) { return diff; }
                return {
                    ...diff,
                    [key]: (next as any)[key]
                };
            }, {});
            // console.log(JSON.stringify(diffs, null, 2));
        } else if (next) {
            // console.log(JSON.stringify(next, null, 2));
        } else {
            console.error('next state is not defined');
        }
    })
).subscribe();

const dispatch: Dispatch = (partial: Partial<State>) => {
    // console.log('*** DISPATCH ***', JSON.stringify(partial, null, 2));
    state = Object.assign({}, state, partial);
    state$.next(state);
};

@Injectable({
    providedIn: 'root',
}) export class StoreService {

    constructor() {
    }

    state$: Observable<State> = state$.asObservable();

    dispatch: Dispatch = dispatch;

    getState(): State {
        return state;
    }

    hydrate(savedState: State): void {
        state = savedState;
        state$.next(state);
    }

    clear(): void {
        state = getEmptyState();
        state$.next(state);
    }
}
