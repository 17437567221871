import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { InitService } from './services/init.service';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        ComponentsModule,
        ModalModule.forRoot(),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            // overeni platnosti tokenu a stazeni dat uzivatele
            useFactory: (initService: InitService) => () => initService.initializeApp(),
            deps: [InitService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        { provide: LOCALE_ID, useValue: 'cs' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(trace: Sentry.TraceService) { }

}
