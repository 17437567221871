import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from '../../services/store.service';
import { User } from '../../models/user';
import { UserRole } from '../../models/user-role';
import { Router } from '@angular/router';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent {

    public error = '';
    public userEmail: string | undefined = this.store.getState().user?.email;

    constructor(
        public userService: UserService,
        public modalRef: BsModalRef,
        private alertService: AlertService,
        private store: StoreService,
        private router: Router,
    ) {
    }

    public async onResendVerificationEmailClick(): Promise<void> {
        if (!this.userEmail) {
            return;
        }
        this.error = '';
        try {
            await this.userService.resendVerificationEmail(this.userEmail, 'ADMIN');
        } catch (err) {
            this.error = err.message;
        }
    }

    public async onConfirmClick(): Promise<void> {
        this.error = '';
        try {
            await this.userService.checkEmailVerified();
        } catch (err) {
            this.error = err.message;
            return;
        }
        const user: User | undefined = this.store.getState().user;
        if (user && !user.isEmailVerified) {
            this.error = 'Chyba při ověření. Zkontrolujte zda jste klepnuli na odkaz, který jsme vám zaslali v e-mailu.';
        } else {
            // success
            this.alertService.presentToast('Děkujeme! Registrace proběhla úspěšně.');
            this.modalRef.hide();
            if (user && user.role === UserRole.ADMIN) {
                this.router.navigateByUrl('/admin');
            } else {
                this.router.navigateByUrl('/organizer');
            }
        }
    }

    public onClosePopupClick(): void {
        this.modalRef.hide();
        this.store.clear();
        this.router.navigateByUrl('/auth/sign-in');
    }
}
