import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { PreviewComponent } from './preview/preview.component';
import { ListViewComponent } from './list-view/list-view.component';
import { SelfClosingAlertComponent } from './self-closing-alert/self-closing-alert.component';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { EventStatusComponent } from './event-status/event-status.component';
import { AlertComponent } from './alert/alert.component';
import { PhotoSelectorComponent } from './photo-selector/photo-selector.component';
import { DatepickerParserFormatter, DatepickerAdapter } from '../services/datepicker.adapter';
import { TagPickerComponent } from './tag-picker/tag-picker.component';
import { TagViewComponent } from './tag-view/tag-view.component';
import { WorkerTagViewComponent } from './worker-tag-view/worker-tag-view.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { TextareaCounterComponent } from './textarea-counter/textarea-counter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableSortableHeaderDirective } from './table-sortable-header/table-sortable-header.directive';
import { BackButtonDirective } from './back-button.directive';
import { PhonePipe } from '../pipes/phone.pipe';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';

@NgModule({
    declarations: [
        ConfirmComponent,
        PreviewComponent,
        SelfClosingAlertComponent,
        ListViewComponent,
        EventStatusComponent,
        AlertComponent,
        PhotoSelectorComponent,
        TagPickerComponent,
        TagViewComponent,
        WorkerTagViewComponent,
        ErrorViewComponent,
        TextareaCounterComponent,
        TableSortableHeaderDirective,
        BackButtonDirective,
        PhonePipe,
        ChangePasswordComponent,
        EmailVerificationComponent
    ],
    exports: [
        ConfirmComponent,
        PreviewComponent,
        SelfClosingAlertComponent,
        ListViewComponent,
        EventStatusComponent,
        PhotoSelectorComponent,
        TagPickerComponent,
        TagViewComponent,
        NgbDatepickerModule,
        WorkerTagViewComponent,
        ErrorViewComponent,
        TextareaCounterComponent,
        BackButtonDirective,
        PhonePipe,
        ChangePasswordComponent,
        EmailVerificationComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        NgbPaginationModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: DatepickerAdapter },
        { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter }
    ]
})
export class ComponentsModule { }
