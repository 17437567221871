import { Tag } from './tag';

export interface TagGroup {
    id: number;
    title: string;
    tags: Tag[];
}

export const dtoToTagGroups = (dto: any): TagGroup[] => {
    return dto as TagGroup[];
};
