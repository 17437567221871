import * as moment from 'moment';
import { BASE_URL } from '../../config';

export interface Provider {
    id: string;
    name: string;
    surname: string;
    birthdate: string;
    phone: string;
    email: string;
    points: number;
    description: string;
    isBlocked: boolean;
    promoCode: string;
    avatar: string;
    isEmailVerified: boolean;
    tags: number[];
    age: number;
}

export const dtoToProvider = (dto: any): Provider => {
    if (dto.birthdate) {
        dto.age = moment().diff(moment(dto.birthdate), 'years');
    }
    if (dto.tags) {
        dto.tags = dto.tags.map((t: any) => t.id);
    }
    if (dto.avatar?.indexOf('http') < 0) {
        dto.avatar = BASE_URL + dto.avatar;
    }
    return dto as Provider;
};
