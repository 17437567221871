<form style='width:100%'>
    <div class="row">
        <div class='col-3'>
            <div class="form-group">
                <label>Label</label>
                <input type="text" class="form-control" placeholder="Placeholder">
            </div>
            <div class="form-group">
                <label>Label</label>
                <input type="text" class="form-control" placeholder="Placeholder" disabled>
            </div>
            <div class="form-group">
                <label>Label</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Placeholder">
                    <div class="input-group-append">
                        <span class="input-group-text"><img src='assets/icons/zamek.svg'/></span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Label</label>
                <select class="form-control" id="exampleFormControlSelect1">
            <option disabled selected>Zvolte počet bodů</option>
            <option>100</option>
            <option>200</option>
            <option>3300</option>
        </select>
            </div>
            <div class="form-group">
                <label>Label</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><img src='assets/icons/search.svg'/></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Placeholder">
                </div>
            </div>
            <div class="form-group">
                <label>Label</label>
                <input type="text" class="form-control is-invalid" placeholder="Placeholder" required>
                <small class="text-red-400">
                    Error msg
                </small>
            </div>
        </div>
        <div class='col-3'>
            <br/>
            <button type="button" class="btn btn-primary">Primary button<img src='assets/icons/plus-w.svg' class='ml-2' /></button>
            <br/>
            <br/>
            <button type="button" class="btn btn-secondary">Secondary button</button>
            <br/>
            <br/>
            <button type="button" class="btn btn-outline-primary">Tertiary button</button>
            <br/>
            <br/>
            <div class='btn btn-primary btn-sm btn--icon'>Primary button<img src='assets/icons/plus-w.svg' class='ml-2' /></div>
            <br/>
            <br/>
            <button class='btn btn-secondary btn-sm'>Secondary button</button>
            <br/>
            <br/>
            <button type="button" class="btn btn-outline-primary btn-sm"><img src='assets/icons/delete.svg' class='mr-2' />Tertiary button</button>
            <br/>
            <br/>
            <button class='btn btn-success'>Secondary button</button>
        </div>
        <div class='col-3 tags'>
            <br/>
            <div class='event-status'>
                Nadcházející
            </div>
            <br/>
            <br/>
            <div class='event-status event-status--upcoming'>
                Nadcházející
                <img src='assets/icons/select-small.svg' class='ml-1' />
            </div>
            <br/>
            <br/>
            <div class='event-status event-status--done'>
                Proběhla
            </div>
            <br/>
            <br/>
            <div class='event-status event-status--cancelled'>
                Zrušena
            </div>
            <br/>
            <br/>
            <div class='tag tag--lightblue'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--gray'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--sm tag--green'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--blue-outline'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--lightblue'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--mediumgray'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--blue'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
            <div class='tag tag--lightgray'>
                Charakteristika 1
            </div>
            <br/>
            <br/>
        </div>
    </div>
    <div class="row">
        <div class='col-6'>
            <div class="form-group">
                <label>Label</label>
                <textarea class="form-control" rows="3"></textarea>
            </div>
        </div>
    </div>
</form>
