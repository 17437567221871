import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreviewComponent } from './components/preview/preview.component';
import { AuthGuard } from './guards/auth.guard';
import { TagService } from './services/tag.service';
import { ActiveUserGuard } from './guards/active-user.guard';
import { VerifyEmailComponent } from './modules/auth/pages/verify-email/verify-email.component';
import { NewPasswordComponent } from './modules/auth/pages/new-password/new-password.component';
import { InvalidateTokenComponent } from './modules/auth/pages/invalidate-token/invalidate-token.component';

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/auth/auth.module').then(m => m.AuthModule)
            }
        ]
    },
    {
        path: 'organizer',
        canActivate: [AuthGuard, ActiveUserGuard],
        resolve: { tags: TagService },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/organizer/organizer.module').then(m => m.OrganizerModule)
            }
        ]
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, ActiveUserGuard],
        resolve: { tags: TagService },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/admin/admin.module').then(m => m.AdminModule)
            }
        ]
    },
    {
        path: 'preview',
        component: PreviewComponent,
    },
    {
        path: 'validate-email-token',
        component: VerifyEmailComponent
    },
    {
        path: 'new-password',
        component: NewPasswordComponent
    },
    {
        path: 'invalidate-token',
        component: InvalidateTokenComponent
    },
    {
        path: '**',
        redirectTo: '/auth/sign-in'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
