import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailVerificationComponent } from '../components/email-verification/email-verification.component';
import { UserService } from '../services/user.service';
import { StoreService } from '../services/store.service';
import { UserRole } from '../models/user-role';

@Injectable({
    providedIn: 'root'
})
export class ActiveUserGuard implements CanActivate {


    constructor(
        public router: Router,
        private userService: UserService,
        private bsModalService: BsModalService,
        private store: StoreService,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.store.getState().user;
        const unverifiedUser: boolean | undefined = user && !user.isEmailVerified;
        if (unverifiedUser) {
            this.bsModalService.show(EmailVerificationComponent);
            return false;
        }
        return true;
    }
}
