import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({ selector: 'app-alert-selfclosing', templateUrl: './self-closing-alert.component.html' })
export class SelfClosingAlertComponent implements OnInit {

    @Input() type!: string;
    @Input() timeout!: number;
    @Input() onClose!: () => void;
    @ViewChild('alert', { static: false }) alert!: NgbAlert;

    ngOnInit(): void {
        setTimeout(() => {
            this.alert.close().subscribe(() => {
                this.onClose();
            });
        }, this.timeout);
    }
}
