import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SENTRY_DSN } from './config';

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: SENTRY_DSN,
        release: environment.sentry_release,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['http://p5022.dev.inited.cz/api', 'https://poradatel.bubblyapp.cz/api'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
