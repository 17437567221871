import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-invalidate-token',
    templateUrl: './invalidate-token.component.html',
    styleUrls: ['./invalidate-token.component.scss']
})
export class InvalidateTokenComponent implements OnInit {

    public error = '';
    public success = false;
    public app = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const token = this.activatedRoute.snapshot.queryParams.token;
        this.app = this.activatedRoute.snapshot.queryParams.app;
        const tokenType = this.activatedRoute.snapshot.queryParams.type;
        try {
            await this.userService.invalidateToken(token, tokenType);
        } catch (err) {
            console.log('error', err);
            this.error = err.message;
            return;
        }
        this.success = true;
    }

}
