import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { LoadingService } from './services/loading.service';
import { AlertService } from './services/alert.service';
import { UserService } from './services/user.service';
import { StoreService } from './services/store.service';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    authSectionActive$ = this.router.events.pipe(
        filter(evt => evt instanceof NavigationEnd),
        map(() => this.router.url.includes('auth')
        || this.router.url.includes('validate-email-token')
        || this.router.url.includes('new-password')
        || this.router.url.includes('invalidate-token')),
    );
    user$ = this.store.state$.pipe(map(state => state.user));
    loading$ = this.loadingService.loading$.pipe(debounceTime(0));

    constructor(
        public alertService: AlertService,
        private store: StoreService,
        private loadingService: LoadingService,
        private router: Router,
        private userService: UserService,
    ) {
        this.initialize();
    }

    private async initialize(): Promise<void> {
        registerLocaleData(localeCs, 'cs');
        this.userService.initialize();
    }

}
