<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClosePopupClick()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body grey-color">
    <i class="fas fa-envelope"></i>
    <h4>Ověření e-mailové adresy</h4>
    <p>Pro pokračování prosím ověřte svou e-mailovou adresu<br/> kliknutím na odkaz zaslaný na {{ userEmail }}</p>
    <div *ngIf='error' class='form-error-message'>
        {{error}}
    </div>
    <div class="d-flex flex-row align-items-center pt-4">
        <button (click)='onConfirmClick()' class="btn btn-primary mr-4" value="Přihlásit se">Už mám ověřeno</button>
        <p class="mb-0 m-l-30">
            <a (click)='onResendVerificationEmailClick()' class='underline pointer'>Znovu zaslat ověřovací e-mail</a>
        </p>
    </div>
</div>
