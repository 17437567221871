import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';

@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '. ';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
    }
}

@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<string> {

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = DateTime.fromISO(value);
            const struct = {
                day: date.day,
                month: date.month,
                year: date.year
            };
            return struct;
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        return date ? DateTime.fromObject(date).toISODate() : null;
    }
}
