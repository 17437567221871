import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TagGroup } from '../models/tag-group';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
}) export class TagService implements Resolve<void> {

    private groups: TagGroup[] | undefined;

    constructor(
        private apiService: ApiService,
        private alertService: AlertService,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
    ) {
    }

    async resolve(): Promise<void> {
        try {
            await this.apiLoadTagGroups();
        } catch (err) {
            this.alertService.presentToast(err.message, 'danger', 3000);
            throw err;
        }
    }

    async apiLoadTagGroups(): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            this.groups = await this.apiService.getTagGroups().toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async addTagGroup(name: string): Promise<void> {
        if (this.groups?.find(gr => gr.title.toLowerCase() === name.toLowerCase())) {
            throw new Error('Kategorie tagů s tímto názvem již existuje.');
        }

        this.loadingService.loading$.next(true);
        try {
            await this.apiService.addTagGroup(name).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async updateTagGroupName(groupId: number, name: string): Promise<void> {
        if (this.groups?.find(gr => gr.title.toLowerCase() === name.toLowerCase() && gr.id !== groupId)) {
            throw new Error('Kategorie tagů s tímto názvem již existuje.');
        }
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.updateTagGroupName(groupId, name).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async addTag(groupId: number, tagName: string): Promise<void> {
        const group = this.groups?.find(gr => gr.id === groupId);
        if (group?.tags.find(tag => tag.title.toLowerCase() === tagName.toLowerCase())) {
            throw new Error('Tag s tímto názvem již existuje.');
        }

        this.loadingService.loading$.next(true);
        try {
            await this.apiService.addTag(groupId, tagName).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async deleteGroup(groupId: number): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.deleteTagGroup(groupId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    async removeTag(tagId: number): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.removeTag(tagId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    getTagGroups(): TagGroup[] {
        return this.groups!;
    }

    isAnyTagAvailable(): boolean {
        return this.groups!.some(it => !!it.tags.length);
    }

}
