import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

    public form: FormGroup = this.fb.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordCheck: ['', [Validators.required]],
    });
    public submitted = false;
    public error: string | undefined = undefined;
    public success = false;
    public app = '';
    private token = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private fb: FormBuilder,
        private router: Router,
    ) {
    }

    public get password(): AbstractControl {
        return this.form.get('password') as AbstractControl;
    }

    public get passwordCheck(): AbstractControl {
        return this.form.get('passwordCheck') as AbstractControl;
    }

    public ngOnInit(): void {
        this.token = this.activatedRoute.snapshot.queryParams.token;
        this.app = this.activatedRoute.snapshot.queryParams.app;
        console.log(this.token, this.app);
    }

    public async onSubmitClick(): Promise<void> {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid || this.form.value.password !== this.form.value.passwordCheck) {
            return;
        }
        this.setPassword();
    }

    public async setPassword(): Promise<void> {
        try {
            await this.userService.setPassword(this.token, this.form.value.password);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.success = true;
    }

    public onContinueToSiteClick(): void {
        this.router.navigateByUrl('/');
    }

}
