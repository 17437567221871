import { BASE_URL } from 'src/config';

export interface Organizer {
    id: string;
    name: string;
    email: string;
    phone: string;
    description: string;
    address: string;
    web: string;
    image: string;
    ic: string;
    isBlocked: boolean;
}

export const dtoToOrganizer = (dto: any): Organizer => {
    if (dto.image?.indexOf('http') < 0) {
        dto.image = BASE_URL + dto.image;
    }
    return dto as Organizer;
};
