import { EventApplicantStatus } from './event-applicant-status';
import { dtoToProvider, Provider } from './provider';

export interface EventApplicant {
    eventId: string;
    worker: Provider;
    status: EventApplicantStatus;
    rating?: number;
}

export const dtoToEventApplicant = (dto: any) => {
    return {
        eventId: dto.eventId,
        status: dto.organizerUserStatus,
        worker: dtoToProvider(dto.user),
        rating: dto.points,
    };
};
