import { BASE_URL } from 'src/config';
import { Event } from './event';
import { dtoToEventApplicant, EventApplicant } from './event-applicant';

export interface EventDetail extends Event {
    description: string;
    applicants: EventApplicant[];
    tags: number[];
    image: string;
}

export const dtoToEventDetail = (dto: any): EventDetail => {
    if (dto.event.image?.indexOf('http') < 0) {
        dto.event.image = BASE_URL + dto.event.image;
    }
    return {
        ...dto.event,
        tags: dto.event.tags.map((tag: any) => tag.id),
        applicants: dto.interestedUsers.map((it: any) => dtoToEventApplicant(it)),
    };
};

